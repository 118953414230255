import { useState, useEffect } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  TextField,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { tokens } from '../../theme';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import HistoryIcon from '@mui/icons-material/History';
import PasswordIcon from '@mui/icons-material/Password';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import BlockIcon from '@mui/icons-material/Block';
import { useAuth } from '../../context/AuthContext';
import LogoutIcon from '@mui/icons-material/Logout';
import api, { verifyToken } from '../../lib/axios';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate(); // Hook to navigate programmatically
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => {
        setSelected(title);
        navigate(to); // Navigate programmatically
      }}
      icon={icon}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const Sidebarr = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tokenEmail, setTokenEmail] = useState('');
  const [tokenPassword, setTokenPassword] = useState('');
  const [tokenOTP, setTokenOTP] = useState('');
  const [showTokenInput, setShowTokenInput] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [errorToken, setErrorToken] = useState('');
  const location = useLocation(); // Get current location
  const [selected, setSelected] = useState(getActivePath(location.pathname)); // Initialize state based on current path
  const employee = localStorage.getItem('employee');
  const token = localStorage.getItem('token');
  const { handleLogout, isAdmin, role } = useAuth();

  // Function to determine the active path
  function getActivePath(pathname) {
    if (pathname === '/') return 'Dashboard';
    if (pathname === '/addBonus') return 'Add Bonus';
    if (pathname === '/bonus-history') return 'Bonus History';
    if (pathname === '/prize-codes') return 'Prize Codes';
    if (pathname === '/prize-list') return 'Prize List';
    if (pathname === '/blocked-users') return 'Blocked Users';
    if (pathname === '/bonus-types') return 'Bonus Types';
    if (pathname === '/register') return 'Register Employee';
    if (pathname === '/manage-employees') return 'Manage Employees';
    return '';
  }

  // Update selected state when location changes
  useEffect(() => {
    setSelected(getActivePath(location.pathname));
  }, [location.pathname]);

  const handleTokenClick = async () => {
    if (!showTokenInput) {
      try {
        const response = await api.get('/api/manage-token', {
          headers: {
            Authorization: token,
          },
        });
        if (!response.data.noSavedCreditentials) {
          setIsSaved(true);
          setTokenEmail(response.data.savedEmail);
          setTokenPassword(response.data.savedPassword);
        } else if (response.data.noSavedCreditentials) {
          setIsSaved(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
    setShowTokenInput(!showTokenInput);
  };

  const handleTokenSubmit = async () => {
    setIsSubmitting(true);
    const isTokenValid = await verifyToken(token);
    if (!isTokenValid) {
      handleLogout();
      return;
    }
    try {
      await api.post(
        '/api/manage-token',
        {
          tokenEmail: tokenEmail,
          tokenPassword: tokenPassword,
          tokenOTP: tokenOTP,
          isGonnaSave: isSaved,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setTokenEmail('');
      setTokenPassword('');
      setTokenOTP('');
      alert('Changed token sucessfully!!');
      setShowTokenInput(false);
    } catch (error) {
      console.log(error);
      setErrorToken(`${error.response.data.message}`);
    }
    setIsSubmitting(false);
  };

  return (
    <Box display="flex">
      <Sidebar
        collapsed={isCollapsed}
        backgroundColor={`${colors.primary[400]} !important`}
        sx={{
          height: '100vh',
        }}
      >
        <Menu
          iconShape="square"
          menuItemStyles={{
            button: ({ level, active }) => {
              if (level === 0 || level === 1) {
                return {
                  '&:hover': {
                    backgroundColor: 'transparent !important',
                    color: '#868dfb !important',
                  },
                  color: active && '#6870fa !important',
                };
              }
            },
          }}
        >
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: '10px 0 20px 0',
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography
                  variant="h3"
                  color={colors.grey[100]}
                  sx={{ textTransform: 'uppercase' }}
                >
                  {role}
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {!isCollapsed && (
            <Box mb="25px">
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: '10px 0 0 0' }}
                >
                  {employee}
                </Typography>
              </Box>
            </Box>
          )}
          <Box paddingLeft={isCollapsed ? undefined : '10%'}>
            <Item
              title="Dashboard"
              to="/"
              icon={<DashboardIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
          <Box paddingLeft={isCollapsed ? undefined : '10%'}>
            <Item
              title="Bonus History"
              to="/bonus-history"
              icon={<HistoryIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
          <Box paddingLeft={isCollapsed ? undefined : '10%'}>
            <Item
              title="Prize Codes"
              to="/prize-codes"
              icon={<PasswordIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
          {isAdmin() === true && (
            <Menu
              iconShape="square"
              menuItemStyles={{
                button: ({ level, active }) => {
                  if (level === 0 || level === 1) {
                    return {
                      '&:hover': {
                        backgroundColor: 'transparent !important',
                        color: '#868dfb !important',
                      },
                      color: active && '#6870fa !important',
                    };
                  }
                },
              }}
            >
              <Box paddingLeft={isCollapsed ? undefined : '10%'}>
                <Item
                  title="Prize List"
                  to="/prize-list"
                  icon={<FormatListBulletedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </Box>
              <Box paddingLeft={isCollapsed ? undefined : '10%'}>
                <Item
                  title="Blocked Users"
                  to="/blocked-users"
                  icon={<BlockIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </Box>
              <Box paddingLeft={isCollapsed ? undefined : '10%'}>
                <Item
                  title="Bonus Types"
                  to="/bonus-types"
                  icon={<TypeSpecimenIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </Box>
              <Box paddingLeft={isCollapsed ? undefined : '10%'}>
                <Item
                  title="Manage Employees"
                  to="/manage-employees"
                  icon={<PeopleAltIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </Box>
              <Box paddingLeft={isCollapsed ? undefined : '10%'}>
                <MenuItem
                  style={{
                    color: colors.grey[100],
                  }}
                  onClick={() => {
                    handleTokenClick();
                  }}
                  icon={<AdminPanelSettingsIcon />}
                >
                  <Typography>Handle TOKEN</Typography>
                </MenuItem>
              </Box>
            </Menu>
          )}
          <Box paddingLeft={isCollapsed ? undefined : '10%'}>
            <div onClick={handleLogout}>
              <Item
                title="Logout"
                icon={<LogoutIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
          </Box>
        </Menu>
      </Sidebar>
      {showTokenInput && (
        <Box
          style={{
            padding: '16px',
            border: '1px solid grey',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: colors.primary[400],
            zIndex: 1000,
          }}
        >
          <Typography variant="h1" marginTop="10px" alignSelf="center">
            Handle Token
          </Typography>
          <Typography variant="h6" marginTop="10px">
            Enter Email for BetConstruct
          </Typography>
          <TextField
            placeholder="Enter e-mail"
            fullWidth
            style={{ marginTop: '16px', color: colors.blueAccent[900] }}
            value={tokenEmail}
            onChange={(e) => setTokenEmail(e.target.value)}
          />
          <Typography variant="h6" marginTop="10px">
            Enter password for BetConstruct
          </Typography>
          <TextField
            placeholder="Enter password"
            type="password"
            fullWidth
            style={{ marginTop: '16px', color: colors.blueAccent[900] }}
            value={tokenPassword}
            onChange={(e) => setTokenPassword(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox style={{ color: colors.grey[300] }} />}
            checked={isSaved}
            label="Save Password"
            onChange={() => setIsSaved(!isSaved)}
          />
          <Typography variant="h6" marginTop="10px">
            Enter OTP for BetConstruct
          </Typography>
          <TextField
            placeholder="Current OTP Number in Authenticator"
            fullWidth
            style={{ marginTop: '16px', color: colors.blueAccent[900] }}
            value={tokenOTP}
            onChange={(e) => setTokenOTP(e.target.value)}
          />
          {errorToken && (
            <Typography color="error" marginTop="10px">
              {errorToken}
            </Typography>
          )}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginTop="15px"
          >
            <Button
              variant="contained"
              disabled={isSubmitting}
              color="primary"
              style={{
                marginTop: '16px',
                width: '5rem',
                border: `1px solid ${colors.grey[100]}`,
                backgroundColor: isSubmitting
                  ? colors.grey[700]
                  : colors.blueAccent[900],
                color: isSubmitting ? colors.grey[400] : colors.grey[100],
              }}
              onClick={() => handleTokenSubmit()}
            >
              {isSubmitting ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: colors.blueAccent[600],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              ) : (
                'Submit'
              )}
            </Button>
            <Button
              variant="contained"
              disabled={isSubmitting}
              color="primary"
              style={{
                marginTop: '16px',
                width: '5rem',
                border: `1px solid ${colors.grey[100]}`,
                backgroundColor: isSubmitting
                  ? colors.grey[700]
                  : colors.blueAccent[900],
                color: isSubmitting ? colors.grey[400] : colors.grey[100],
              }}
              onClick={() => {
                setTokenEmail('');
                setTokenPassword('');
                setErrorToken('');
                setTokenOTP('');
                setShowTokenInput(false);
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Sidebarr;
