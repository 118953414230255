import { useState } from 'react';
import api from '../lib/axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAlert } from '../lib/useAlert';

const PrizeChangeForm = ({
  reloadFunction,
  showPrizeForm,
  prizeId,
  prizeAmount,
  prizeType,
}) => {
  const [amount, setAmount] = useState(
    prizeAmount === 'Kazanamadın' ? '' : prizeAmount
  );
  const [type, setType] = useState(prizeType);
  const [isLose, setIsLose] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = localStorage.getItem('token');
  const showAlert = useAlert();

  // Handle Alerts
  const handleAlert = (message) => {
    showAlert(message, 'success');
    showPrizeForm(false);
    reloadFunction();
  };
  // Handle Close
  const handleClose = () => {
    showPrizeForm(false);
  };

  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    try {
      await api.post(
        '/api/change-prize',
        {
          prizeId: prizeId,
          prizeLabel: isLose ? 'Kazanamadın' : `${amount} ${type}`,
          isWinner: isLose ? false : true,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      handleAlert('Prize changed successfully!');
      reloadFunction();
    } catch (err) {
      handleAlert(`Error editing prize, please try again.`);
    }
    handleClose();
    setIsSubmitting(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <IconButton
        onClick={handleClose}
        style={{ position: 'absolute', top: '8px', right: '8px' }}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h4">Change Prize</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          disabled={isLose}
          label="Prize Amount ( 100, 50, etc. )"
          variant="outlined"
          margin="normal"
          fullWidth
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <FormControl
          disabled={isLose}
          fullWidth
          variant="outlined"
          margin="normal"
        >
          <InputLabel id="role-select-label">Prize Type</InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select"
            value={type}
            onChange={(e) => setType(e.target.value)}
            label="Role"
            style={{
              minWidth: 120,
              maxWidth: 400,
            }}
          >
            <MenuItem value="Freebet">Freebet</MenuItem>
            <MenuItem value="Freespin">Freespin</MenuItem>
            <MenuItem value="Nakit">Nakit</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={isLose}
              onChange={(e) => setIsLose(e.target.checked)} // Toggle between Select and TextField
            />
          }
          label="Set as Lose ( Kazanamadin )"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          fullWidth
          style={{
            marginTop: '16px',
            border: '1px solid #727681',
          }}
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </Button>
      </form>
    </Box>
  );
};

export default PrizeChangeForm;
