import React, { useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Header from '../../components/Header';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import api from '../../lib/axios';
import { useAuth } from '../../context/AuthContext';
import { verifyToken } from '../../lib/axios';
import PrizeChangeForm from '../../components/PrizeChangeForm';
import Loader from '../../components/Loader';

const PrizeList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const token = localStorage.getItem('token');
  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.8 },
    {
      field: 'label',
      headerName: 'Prize Label',
      flex: 1,
      disableClickEventBubbling: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.15,
      renderCell: ({ row: { id, label } }) => {
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            height={1}
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  color: colors.primary[700],
                },
                padding: '10px',
              }}
              onClick={() => handleClickChangePrize(id, label)}
            >
              <EditIcon />
            </Box>
          </Box>
        );
      },
      disableClickEventBubbling: true,
    },
  ];
  const [prizeList, setPrizeList] = useState([]);
  const [showPrizeTypeChangeFrom, setShowPrizeTypeChangeForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(Number);
  const [amount, setAmount] = useState('');
  const [type, setType] = useState('Freespin', 'Freebet', 'Nakit');
  const { handleLogout } = useAuth();

  // Get Prizes
  const fetchPrizes = async () => {
    setIsLoading(true);
    const isTokenValid = await verifyToken(token);
    if (!isTokenValid) {
      handleLogout();
      return;
    }
    try {
      const response = await api.get('/api/get-prizes', {
        headers: {
          Authorization: token,
        },
      });
      setPrizeList(response.data);
    } catch (error) {
      console.log(error);
      alert('Error getting prize list!');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPrizes();
  }, []);

  // Handle click change bonus type
  const handleClickChangePrize = async (id, label) => {
    setId(id);
    const labelWords = label.split(' ');
    setAmount(labelWords[0]);
    setType(labelWords[1]);
    setShowPrizeTypeChangeForm(!showPrizeTypeChangeFrom);
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Prize List"
          subtitle="Manage Prizes"
          color={colors.greenAccent[400]}
          mt={0}
        />
      </Box>
      <Box
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <DataGrid
            checkboxSelection
            disableRowSelectionOnClick
            initialState={{
              sorting: {
                sortModel: [{ field: 'id', sort: 'desc' }],
              },
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            rows={prizeList}
            columns={columns}
            pageSizeOptions={[10, 25, 50]}
          />
        )}
      </Box>
      {showPrizeTypeChangeFrom && (
        <Box
          style={{
            padding: '16px',
            border: '1px solid grey',
            position: 'absolute',
            top: '50%',
            left: '50%',
            height: 'auto',
            width: '25rem',
            transform: 'translate(-50%, -50%)',
            backgroundColor: colors.primary[400],
            zIndex: 1000,
          }}
        >
          <PrizeChangeForm
            reloadFunction={fetchPrizes}
            showPrizeForm={setShowPrizeTypeChangeForm}
            prizeId={id}
            prizeAmount={amount}
            prizeType={type}
          />
        </Box>
      )}
    </Box>
  );
};

export default PrizeList;
